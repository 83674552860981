<ng-container
  *ngTemplateOutlet="isTeamsAndHidden ? teamsHeader : (header ?? defaultHeader); context: createContext()"
></ng-container>

<ng-template #defaultHeader>
  <mat-toolbar [ngClass]="['mat-toolbar-tg', tbclass] | clsx">
    @if (showMenuToggle) {
      <shell-menu-toggle></shell-menu-toggle>
    }
    @if (controls) {
      <ng-container *ngTemplateOutlet="controls; context: createContext()"></ng-container>
    } @else {
      <div class="flex-auto"></div>
    }
    <div class="flex items-center space-x-3">
      <div class="flex-auto"></div>
      <div fxHide.lt-sm class="flex flex-row items-center justify-start gap-4">
        @if (showFrill) {
          <tg-frill-button></tg-frill-button>
        }
        @if ((showFrill || controls) && showUser) {
          <mat-divider vertical class="h-8" fxHide.lt-md></mat-divider>
        }
        @if (showUser) {
          <app-user-menu></app-user-menu>
        }
      </div>
    </div>
    @if (rightControls) {
      <ng-container *ngTemplateOutlet="rightControls; context: createContext()"></ng-container>
    }
  </mat-toolbar>

  @if (content) {
    <ng-container *ngTemplateOutlet="content; context: createContext()"></ng-container>
  }
</ng-template>
<ng-template #teamsHeader>
  <ng-container *teams-hide>
    <ng-container *ngTemplateOutlet="header ?? defaultHeader; context: createContext()"></ng-container>
  </ng-container>
</ng-template>
