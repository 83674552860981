import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrillButtonComponent } from './frill-button.component';
import { FrillButtonService } from './frill-button.service';
import { MaterialModule } from '@app/material.module';

@NgModule({
  declarations: [FrillButtonComponent],
  imports: [CommonModule, MaterialModule],
  exports: [FrillButtonComponent],
})
export class FrillButtonModule {
  static forRoot(): ModuleWithProviders<FrillButtonModule> {
    return {
      ngModule: FrillButtonModule,
      providers: [FrillButtonService],
    };
  }
}
